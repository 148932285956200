import { render, staticRenderFns } from "./ProfileBar.vue?vue&type=template&id=178192ff&scoped=true&"
import script from "./ProfileBar.vue?vue&type=script&lang=js&"
export * from "./ProfileBar.vue?vue&type=script&lang=js&"
import style0 from "./ProfileBar.vue?vue&type=style&index=0&id=178192ff&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "178192ff",
  null
  
)

export default component.exports