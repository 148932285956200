<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      content-class="profile-card"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          light
          v-bind="attrs"
          v-on="on"
          icon
        >
          <v-avatar color="white">
            <img
              v-if="user.avatar"
              :src="getAvatar()"
              :alt="user.fullname"
            >

            <span v-else class="black--text headline">
              {{ user.fullname }}
            </span>
          </v-avatar>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar>
              <v-avatar color="primary">
                <img
                  v-if="user.avatar"
                  :src="user.avatar.url"
                  :alt="user.fullname"
                >

                <span v-else class="white--text headline">
              {{ user.fullname }}
            </span>
              </v-avatar>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                {{ user.fullname }}
              </v-list-item-title>
              <v-list-item-subtitle>
                Administrador
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  import {mapState} from 'vuex'

    export default {
        name: "ProfileBar",
      data: () => ({
        fav: true,
        menu: false,
        message: false,
        hints: true,
      }),
      computed: {
        ...mapState(['user']),
      },
      methods: {
        openProfile() {
          this.goTo('AdminUsuarioPerfil')
          this.menu = false
        },
        getAvatar() {
          if (this.user.avatar) {
            return strapiAsset(this.user.avatar.url)
          }
        }
      },
    }
</script>

<style scoped>
  .profile-card {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
</style>
